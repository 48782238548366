
#footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 3.5rem;
  background: var(--chakra-colors-white);
}

#content-wrap {
  padding-bottom: 3.5rem;
}

#page-container {
  position: relative;
  min-height: 100vh;
}
